<template>
  <v-container d-flex justify-center flex-wrap  flat
      height="100vh"
      tile>
        <v-card class="updateaplicacion" align-self-xl-center>
            <!-- <div class="logo"><img src='@/assets/img/logo.svg'></div> -->
            <v-icon class='icon'>system_update</v-icon>
            <p>Se necesita actualizar la aplicación para poder continuar.</p><br>

            <!-- <p>Disculpe las molestias.</p> -->
            <a href='https://play.google.com/store/apps/details?id=bropemor-servicios.app' class="button" >Actualizar</a>
            <button @click="reloadPage" class="button" >Recargar página</button>

        </v-card>
  </v-container>
</template>
<script>
import { gw } from "@/gw.js";
export default {
  name: "UpdateAplicacion",
  mixins: [gw],
  data: () => ({ }),
  mounted() {
      //console.log("Hola UpdateAplicacion");
  },
  methods: {
    reloadPage(){
      window.location.reload();
    }
  }
};
</script>