<template>
    <div class="toolbar-wrapp" v-if="auth && (auth.coduser)">
        <v-toolbar fixed="fixed" class="toolbar">
            <img
                class='logo'
                @click="changelocation"
                src='@/assets/img/logo.svg'
                alt="Bropemor Servicios">

                <!-- <div v-if="user.rol=='agente'" class="info_user">
                    <div v-ripple v-if="user && user.rol=='cliente'" class="grey--text"> 
                        {{ $t('toolbar_cliente') }} : 
                        <span @click="Tools.go('carrito')" v-if="user.nombre" >{{ user.nombre }}</span>
                        <span @click="Tools.go('clientes')" class='danger button' v-else >{{ $t('toolbar_msg_cliente') }}</span>
                    </div>
                    <div v-ripple class="color--secondary" v-if="user && user.rol=='agente'">
                        {{ $t('toolbar_agente')}} : <span>{{ user.nombre }}</span>
                    </div>
                </div> -->

                <!-- <div v-if="!user.agente" class="info_user">                    
                    <div v-ripple class="color--secondary" v-if="user && user.cliente" @click="Tools.go('cuenta')">
                        {{ $t('toolbar_welcome')}} <span>{{ user.cliente.nombre }}</span>
                    </div>
                </div> -->
 
                <v-spacer></v-spacer>
                
                <!-- <label v-if="user && user.rol=='agente'">
                    <router-link class="nav-link" to="/clientes">
                        <v-btn class="v-btn--text v-btn--flat" :class="(active_view === 'clientes') ? 'black--text' : 'grey--text'">
                            <v-icon class=''>group</v-icon>                            
                            <span>{{ $t('toolbar_labperfil') }}</span>
                        </v-btn>
                    </router-link>
                </label> -->

                <!-- <label>
                    <router-link class="nav-link" v-bind:class="{ disabled_button: false }" to="/menu/sga">
                        <v-btn class="v-btn--text v-btn--flat" :class="(active_view === 'sga') ? 'black--text' : 'grey--text'">
                            <v-icon class=''>storage</v-icon>                            
                            <span>{{ $t('toolbar_sga') }}</span>
                        </v-btn>
                    </router-link>
                </label> -->

                <label>
                    <router-link class="nav-link" v-bind:class="{ disabled_button: false }" to="/ordenestrabajo">
                        <v-btn class="v-btn--text v-btn--flat" :class="(active_view === 'mrp') ? 'black--text background-transparent' : 'grey--text background-transparent'">
                            <v-icon class=''>work</v-icon>                            
                            <span>{{ $t('toolbar_mrp') }}</span>
                        </v-btn>                        
                    </router-link>
                </label>

                <label>
                    <v-btn class="v-btn--text v-btn--flat black--text background-transparent" @click="dialogClosseSession=true">
                        <v-icon class="">exit_to_app</v-icon>
                        <!-- <span> Cerrar sesión</span> -->
                    </v-btn>                        
                </label>
                <v-dialog v-model="dialogClosseSession" max-width="290">
                    <v-card>
                    <v-card-title class="headline">¿Cerrar sesión?</v-card-title>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="#555555" text @click="dialogClosseSession=false">Cancelar</v-btn>
                        <v-btn color="#555555" text @click="click_logout">Cerrar sesión</v-btn>
                    </v-card-actions>
                    </v-card>
                </v-dialog>

               <!--  <label>
                    <router-link class="nav-link" to="/buscararticulo">
                        <v-btn class="v-btn--text v-btn--flat" :class="(active_view === 'buscararticulo') ? 'black--text' : 'grey--text'">
                            <v-icon class=''>search</v-icon>                            
                            <span>{{ $t('toolbar_labbuscararticulo') }}</span>
                        </v-btn>                        
                    </router-link>
                </label>                
 -->
                <!--<label>
                     <router-link class="nav-link" to="/carro" >                        
                        <div class="box-shopping-cart">
                            <v-icon class="" :class="(active_view === 'carro') ? 'black--text' : 'grey--text'">shopping_cart</v-icon>
                            <span v-if="cantTotalCarro > 0" class="_badge">{{cantTotalCarro}}</span>
                        </div>
                    </router-link> -->

                     <!-- <router-link class="nav-link" to="/carro" >                        
                        <v-btn class="v-btn--text v-btn--flat">                            
                            <v-icon class="" :class="(active_view === 'carro') ? 'black--text' : 'grey--text'">shopping_cart</v-icon>
                            <span v-if="cantTotalCarro >= 0" class="">{{cantTotalCarro}}</span>
                            <span class="pl-1">{{ $t('toolbar_labcarro2') }}</span>
                        </v-btn>                        
                    </router-link> 

                </label>-->

                <!-- <v-menu offset-y="offset-y">
                    <template v-slot:activator="{ on }">
                        <v-btn icon="icon" v-on="on">
                            <v-icon class='grey--text'>more_vert</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-tile v-for="(item, index) in items" :key="index">
                            <v-list-tile-title @click="handle_function_call(item.action)">{{ item.title }}</v-list-tile-title>
                        </v-list-tile>
                    </v-list>
                </v-menu> -->

            
        </v-toolbar>
    </div>

</template>

<script>
//import Auth from '@/mixins/auth.mixin'
//import EventBus from '@/events/index'
//import EVT from '@/events/eventsnames'
//import {from} from 'rxjs'
//import Tools from '@/services/tools.service'

// const _user = new UsersDbService()
 import { gw } from '@/gw.js'

export default {
    mixins: [gw],
    name: 'ToolBar',
    data: () => ({
        showMenu: false,
        //cantTotalCarro: 0,
        active_view: '',
        items: [],
        dialogClosseSession: false,
        //user: []
    }),       
    mounted () {
        var _this = this;    
        //this.user = this.getLocalStorage('auth') 

        //_this.active_view = this._routerRoot._route.params;

        //console.log(this.user)
        /*EventBus.$on(EVT.DATA_USER_CHANGED, function (user) {
            _this.user = user;
            if (_this.user.carro && _this.user.carro.length > 0) {
                _this.cantTotalCarro = _this.user.carro.length
            }
        })
        EventBus.$on(EVT.UPDATE_TOTALCARRO, function (_totalcarro) {
            if (!_totalcarro) _totalcarro = 0;
            _this.cantTotalCarro = _totalcarro;
        })
        EventBus.$on(EVT.CHANGE_VIEW, function (view) {
            _this.active_view = view;
        })
        EventBus.$on(EVT.UPDATE_i18n_DATA, () => {
            this.items = [
                {
                    title: this.$i18n.t('toolbar_actualizar'),
                    action: 'actualizarapp'
                }, {
                    title: this.$i18n.t('toolbar_preferencias'),
                    action: 'goPreferencias'
                }, {
                    title: this.$i18n.t('toolbar_cerrarsesion'),
                    action: 'closeSession'
                }
                
            ];
        });  */
    },
    methods: {                
        toggleMenu() {
            this.showMenu = !this.showMenu;
        },
        changelocation() {
            this.to('/ordenestrabajo');
        },
        closeSession() {
            /*if (Auth.$currentUser) {
                // from(_user.delete(Auth.$currentUser)).subscribe((result) => {
                //     if (result.ok) {
                //         EventBus.$emit(EVT.DATA_USER_CHANGED, {});
                //         // actualizarApp.borrar_todo();
                //         this.go('login')
                //     }
                // });
                //Auth.$currentUser = null;
                this.user = {};
            }*/
        },
        actualizarapp() {
            EventBus.$emit(EVT.SHOW_UPDATER, true);
        },
        goPreferencias() {
            console.log("TODO: ir a preferencias!");
        },
        handle_function_call(function_name) {
            this[function_name]()
        },
        click_logout() {
            this.dialogClosseSession=false;
            sessionStorage.clear();
            localStorage.clear();
            this.deleteCookie("LK");
            this.deleteCookie("LP");
            this.deleteCookie("LS");
            location.reload();
        }
    }
}
</script>